import styles from './SponsorshipCard.module.css';

const SponsorshipCard = (props) => {

    return(
        <div className={styles.card}>
            <div className={styles.icon}>{props.icon}</div>
            <h3>{props.title}</h3>
            <div className={styles.space}/>
            <p>{props.desc}</p>
        </div>
    )

}

export default SponsorshipCard;