import { lazy, Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.css';
import SponsorsPage from './pages/client/SponsorsPage';

const Navigation = lazy(() => import('./components/navigation/Navigation'));
const Footer = lazy(() => import('./components/footer/Footer'));
const NotFound = lazy(() => import('./pages/client/404'));
const SponsorsGrid = lazy(() => import('./components/grids/SponsorGrid'));
const Home = lazy(() => import('./pages/client/Home'));
const AdminPage = lazy(() => import('./pages/admin/Admin'));
const NewsPage = lazy(() => import('./pages/client/NewsPage'));
const SingleNewsPage = lazy(() => import('./pages/client/SingleNewsPage'));
const CompetitionsPage = lazy(() => import('./pages/client/CompetitionsPage'));
const ClubPage = lazy(() => import('./pages/client/ClubPage'));
const AthletesPage = lazy(() => import('./pages/client/AthletesPage'));
const SingleAthletePage = lazy(() => import('./pages/client/SingleAtheletePage'));
const HistoryPage = lazy(() => import('./pages/client/HistoryPage'));
const MenagmentPage = lazy(() => import('./pages/client/MenagmentPage'));
const AlbumsPage = lazy(() => import('./pages/client/AlbumsPage'));
const PhotosPage = lazy(() => import('./pages/client/PhotosPage'));
const LoginPage = lazy(() => import('./pages/client/LoginPage'));

function App() {

  const router = createBrowserRouter([
    {
      path: '/',
      element: (
          <>
            <Navigation/>
            <SponsorsGrid/>
            <Footer/>
          </>
      ),
      errorElement: (
        <Suspense fallback={<div class="lds-ring"><div></div><div></div><div></div><div></div></div>}>
            <NotFound/>
        </Suspense>
      ), 
      children: [
        {
          index: true,
          path: '', 
          element: (
            <Suspense fallback={
              <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
            }>
                <Home/>
            </Suspense>
          ),  
          loader: () => import('./pages/client/Home').then((module) => module.loader())
        },
        {
          path: 'admin', 
          element: (
            <Suspense fallback={<div class="lds-ring"><div></div><div></div><div></div><div></div></div>}>
                <AdminPage/>
            </Suspense>
          ),
          loader: () => import('./pages/admin/Admin').then((module) => module.loader())
        },
        {
          path: 'aktualnosci', 
          element: (
            <Suspense fallback={<div class="lds-ring"><div></div><div></div><div></div><div></div></div>}>
                <NewsPage/>
            </Suspense>
          ),
          loader: () => import('./pages/client/NewsPage').then((module) => module.loader())
        },
        {
          path: 'aktualnosci/:newsId', 
          element: (
            <Suspense fallback={<div class="lds-ring"><div></div><div></div><div></div><div></div></div>}>
                <SingleNewsPage/>
            </Suspense>
          ), 
          loader: (meta) => import('./pages/client/SingleNewsPage').then((module) => module.loader(meta))
        }, 
        {
          path: 'zawody', 
          element: (
            <Suspense fallback={<div class="lds-ring"><div></div><div></div><div></div><div></div></div>}>
                <CompetitionsPage/>
            </Suspense>
          ), 
          loader: () => import('./pages/client/CompetitionsPage').then((module) => module.loader())
        },
        {
          path: 'klub',
          children: [
            {
              index: true,
              element: (
                <Suspense fallback={<div class="lds-ring"><div></div><div></div><div></div><div></div></div>}>
                    <ClubPage/>
                </Suspense>
              ), 
            },
            {
              path: 'zawodnicy', 
              element: (
                <Suspense fallback={<div class="lds-ring"><div></div><div></div><div></div><div></div></div>}>
                    <AthletesPage/>
                </Suspense>
              ), 
              loader: () => import('./pages/client/AthletesPage').then((module) => module.loader())
            },
            {
              path: 'zawodnicy/:athleteSlug', 
              element: (
                <Suspense fallback={<div class="lds-ring"><div></div><div></div><div></div><div></div></div>}>
                    <SingleAthletePage/>
                </Suspense>
              ),  
              loader: (meta) => import('./pages/client/SingleAtheletePage').then((module) => module.loader(meta)), 
            },
            {
              path: 'sponsorzy', 
              element: <SponsorsPage/>
            },
            {
              path: 'zarzad', 
              element: (
                <Suspense fallback={<div class="lds-ring"><div></div><div></div><div></div><div></div></div>}>
                    <MenagmentPage/>
                </Suspense>
              ),  
              loader: () => import('./pages/client/MenagmentPage').then((module) => module.loader()), 
            },
            {
              path: 'historia', 
              element: (
                <Suspense fallback={<div class="lds-ring"><div></div><div></div><div></div><div></div></div>}>
                    <HistoryPage/>
                </Suspense>
              ),  
            }
          ]
        },
        {
          path: 'galeria', 
          element: (
            <Suspense fallback={<div class="lds-ring"><div></div><div></div><div></div><div></div></div>}>
                <AlbumsPage/>
            </Suspense>
          ),  
          loader: () => import('./pages/client/AlbumsPage').then((module) => module.loader()), 
        },
        {
          path: 'galeria/:albumsSlug',
          element: (
            <Suspense fallback={
              <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
            }>
                <PhotosPage/>
            </Suspense>
          ),  
          loader: (meta) => import('./pages/client/PhotosPage').then((module) => module.loader(meta)), 
        },
        {
          path: 'logowanie',
          element: (
            <Suspense fallback={
              <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
            }>
                <LoginPage/>
            </Suspense>
          ),  
        }
      ]
    }
  ])

  return (
    <Suspense fallback={<div class="lds-ring"><div></div><div></div><div></div><div></div></div>}>
      <RouterProvider router={router}/>
    </Suspense>
  );
}

export default App;

