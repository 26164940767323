import styles from './Banner.module.css';

const Banner = (props) => {

    return(
        <div className={styles.box} style={{backgroundImage: `url(${props.link})`}}>
            <h2>{props.title}</h2>
            <div style={{width: `${props.long}px`}}/>
            <h3>{props.subtitle}</h3>
        </div>
    )

}

export default Banner;